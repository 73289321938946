<template>
  <div class="banner" :style="`height: ${carouselHeight}px`">
    <el-carousel
      :loop="loop"
      :interval="interval"
      :indicator-position="indPosition"
    >
      <el-carousel-item v-for="(chunks, i) in recommendInChunks" :key="i">
        <div class="item-group" v-if="chunks && chunks.length">
          <div v-for="(item, ii) in chunks" :key="ii" class="item-box">
            <img class="bannerPic" :src=" item.label_img" />
            <!-- <img class="bannerPic" :src="appBaseURL + item.label_img" /> -->
            <!-- <div class="bannerPic">{{ii}}</div> -->
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { appBaseURL } from "@/api/base";

export default {
  name: "BannerPc",
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    groupCount: {
      type: Number,
      default: 1,
    },
    interval: {
      type: Number,
      default: 5000,
    },
    data: {
      type: Array,
      default: () => [],
    },
    indPosition: {
      type: String,
      default: "inside",
    },
    carouselHeight: {
      type: Number,
      default: 466,
    },
  },
  data() {
    return {
      appBaseURL,
      banner: {
        progress: 0,
        current: 0,
        size: 4,
        item: [1, 2, 3, 4],
      },
    };
  },
  watch: {
    /* data(res){
			this.banner.item = res;
			this.banner.size = res.length;
			this.banner.progress = (100/res.length).toFixed(0);
		} */
  },
  computed: {
    recommendInChunks() {
      const chunks = [];
      for (
        var i = 0, chunkSize = this.groupCount, l = this.data.length;
        i < l;
        i += chunkSize
      ) {
        chunks.push(this.data.slice(i, i + chunkSize));
      }
      return chunks;
    },
  },
  created() {},
  methods: {
    onBannerChange(res) {
      //banner翻页
      this.banner.progress = ((res + 1) / this.banner.size) * 100;
      this.banner.current = res;
    },
    setActive(type) {
      if (type == "prev") {
        this.$refs.banner.prev();
      } else {
        this.$refs.banner.next();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  background: #fff;
  position: relative;
  padding: 40px 0;
  font-family: GillSans;
  /deep/.el-carousel {
    height: 100%;
    .el-carousel__container {
      height: 100%;
    }
    .el-carousel__indicators {
      .el-carousel__indicator {
        padding: 15px 6px;
        .el-carousel__button {
          background: #ccc;
          height: 3px;
          border-radius: 1px;
        }
      }
    }
  }
  /deep/.el-carousel__arrow {
    top: 33%;
  }
  .item-group {
    width: 100%;
    display: flex;
    justify-content: center;
    .item-box {
      .bannerPic {
        margin: 8px;
        width: 70px;
        height: 70px;
        border: 3px solid #dfdfdf;
        background: lightblue;
        object-fit: cover;
      }
    }
  }
}
</style>
