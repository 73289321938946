<template>
  <div class="home-page">
    <div class="Home">
      <!-- PC端banner轮播 -->
      <BannerPc v-if="$store.state.platform == 'pc'" :data="ad"></BannerPc>
      <BannerMob v-else :data="ad"></BannerMob>
      <!-- 商品轮播 -->
      <CarouselGoods
        :data="recommand"
        v-if="$store.state.platform == 'pc'"
        :groupCount="goodsGroupCount"
      ></CarouselGoods>
      <!-- 品牌logo -->
      <!-- 整屏图片 -->
      <div class="hot" v-if="categoryCover.recommendWebPic">
        <!-- <div class="line1">
          <div class="left"><img class="regular" :src="pics.pic1" /></div>
          <div class="right"><img class="regular" :src="pics.pic2" /></div>
        </div> -->
        <div class="line2">
          <img
            class="regular"
            @click="
              $router.push(`/GoodsList?cateId=${categoryCover.categoryId}`)
            "
            :src="categoryCover.recommendWebPic"
          />
        </div>
      </div>

      <!-- 商品轮播 -->
      <CarouselGoods
        :data="recommandCategory"
        v-if="$store.state.platform == 'pc'"
        :groupCount="goodsGroupCount"
      ></CarouselGoods>

      <!-- 整屏图片 -->
      <div class="hot">
        <div class="line1">
          <div class="left">
            <img
              @click="$router.push(`/GoodsList?cateId=${pics[0].linkId}`)"
              class="regular"
              :src="pics[0] ? pics[0].img : ''"
            />
          </div>
          <div class="right">
            <img
              @click="$router.push(`/GoodsList?cateId=${pics[1].linkId}`)"
              class="regular"
              :src="pics[1] ? pics[1].img : ''"
            />
          </div>
        </div>
        <div class="line2">
          <img
            @click="$router.push(`/GoodsList?cateId=${pics[2].linkId}`)"
            class="regular"
            :src="pics[2] ? pics[2].img : ''"
          />
        </div>
        <div class="line2">
          <img
            @click="$router.push(`/GoodsList?cateId=${pics[3].linkId}`)"
            class="regular"
            :src="pics[3] ? pics[3].img : ''"
          />
        </div>
      </div>
      <CarouselBrands
        v-if="$store.state.platform == 'pc'"
        :groupCount="brandsGroupCount"
        :data="brands"
        :carouselHeight="130"
      ></CarouselBrands>
      <!-- SELECTED -->
      <!-- <div class="selTitle">
        <div class="text">
          Favorites of<br />
          the week
        </div>
      </div>
      <div class="selected">
        <div v-for="(val, index) in recommand" class="item" :key="index">
          <div
            class="pic"
            @click="
              $router.push(
                `/${
                  $store.state.platform == 'pc'
                    ? 'GoodsDetail'
                    : 'Detail_Mobile'
                }?id=${val.spuId}`
              )
            "
          >
            <img class="regular" :src="val.cover" />
          </div>
          <div class="name">{{ val.spuName }}</div>
          <div class="price">€{{ val.price }}</div>
          <div class="addCart" @click="handleAddCart(val)">
            {{ $t("goodDetail.addCart") }}
          </div>
        </div>
      </div> -->
      <!-- RECIEN NACIDO -->
      <!-- <div class="recien">
        <div class="title">Recien nacido</div>
        <div class="block">
          <div class="line1"><img class="regular" :src="pics.pic4" /></div>
          <div class="line2">
            <div class="item">
              <div class="pic"><img class="regular" :src="pics.pic5" /></div>
              <div class="sex">Niña</div>
            </div>
            <div class="item">
              <div class="pic"><img class="regular" :src="pics.pic6" /></div>
              <div class="sex">Niño</div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- sku弹窗 -->
      <div @click.stop="() => {}">
        <el-dialog
          title="Talla"
          :visible.sync="listVisible"
          width="30%"
          :before-close="
            (done) => {
              done();
            }
          "
        >
          <div class="list-content">
            <p
              class="list-sku"
              v-for="(item, index) in skus"
              :key="index"
              @click.stop="
                () => {
                  $bus.$emit('addCart', item.sku_id);
                  listVisible = false;
                }
              "
            >
              {{ item.spec_info.replace(/Talla:/g, "") }}
            </p>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import BannerPc from "./Banner/banner_pc.vue";
import BannerMob from "./Banner/banner_mob.vue";
import CarouselGoods from "../../components/Carousel/goods.vue";
import CarouselBrands from "../../components/Carousel/brands.vue";
import Api from "../../api/request.js";
export default {
  name: "Home",
  components: {
    BannerPc,
    BannerMob,
    CarouselGoods,
    CarouselBrands,
  },
  data() {
    return {
      ad: [],
      pics: [],
      listVisible: false,
      skus: [],
      recommand: [],
      categoryCover: "",
      brands: [],
      recommandCategory: [],
      // 商品轮播组项目数量
      goodsGroupCount: 4,
      // 品牌轮播组项目数量
      brandsGroupCount: 9,
    };
  },
  created() {
    this.getPageData();
  },
  mounted() {
    window.onresize = () => {
      this.changeTableMaxHeight();
    };
    this.changeTableMaxHeight();
  },
  methods: {
    getPageData() {
      //获取推荐商品轮播图等数据
      // this.$loading.start();
      const indexAPI = Api.Index;
      const safeGet = (resp, defaultValue = {}) =>
        (resp.data && resp.data.data) || defaultValue;
      Promise.all([
        // 商品推荐
        indexAPI
          .recommandSpu({
            limit: 12,
            label_id: 2,
          })
          .then((resp) => {
            this.recommand = safeGet(resp, {}).list || [];
          }),
        // 分类推荐
        indexAPI.recommandCategory({ limit: 4 }).then((resp) => {
          console.log(resp)
          const list = safeGet(resp, {}) || [];
          let arr = [];
          let obj = {};
          for (var i = 0, l = list.length; i < l; i++) {
            arr.push(...list[i].list);
          }
          obj = list[0] || [];
          this.categoryCover = obj;
          this.recommandCategory = arr || [];
          console.log(this.categoryCover)
        }),
        indexAPI.advertisement({ type: "index" }).then((resp) => {
          const data = safeGet(resp);
          this.ad = (data.pcIndex1 && data.pcIndex1.ads) || [];
          this.pics = [];
          console.log(data);
          if (data.pcIndex3&&data.pcIndex3.ads.length > 0) {
            for (let i = 0; i < data.pcIndex3.ads.length; i++) {
              this.pics.push(data.pcIndex3.ads[i]);
            }
          }
          if (data.pcIndex4&&data.pcIndex4.ads.length > 0) {
            for (let i = 0; i < data.pcIndex4.ads.length; i++) {
              this.pics.push(data.pcIndex4.ads[i]);
            }
          }
        }),
        // Type = 2 是品牌, 1是商品
        indexAPI.label({ type: 2 }).then((resp) => {
          this.brands = safeGet(resp, []);
        }),
        // indexAPI.pics({ type: "index" }).then((resp) => {
        //   this.pics = safeGet(resp, []).reduce((acc, current) => {
        //     const key = current.positionType.replace("index", "pic");
        //     const value = current.img;
        //     acc[key] = value;
        //     return acc;
        //   }, {});
        // }),

        // new Promise((resolve, reject) => {
        //   Api.Index.recommand({
        //     limit: 10,
        //     label: 4,
        //   }).then((res) => {
        //     this.recommand = res.data.data.list;
        //     resolve();
        //   });
        //   resolve();
        // }),
        // new Promise((resolve, reject) => {
        //   Api.Index.advertisement({
        //     type: "index",
        //   }).then((res) => {
        //     const data = res.data && res.data.data;
        //     this.ad = (data.index && data.index.ads) || [];
        //     resolve();
        //   });
        // }),
        // new Promise((resolve, reject) => {
        //   Api.Index.pics({
        //     type: "indexs",
        //   }).then((res) => {
        //     this.pics = {
        //       pic1: res.data.data.find((item) => {
        //         return item.positionType == "index1";
        //       })
        //         ? res.data.data.find((item) => {
        //             return item.positionType == "index1";
        //           }).img
        //         : "",
        //       pic2: res.data.data.find((item) => {
        //         return item.positionType == "index2";
        //       })
        //         ? res.data.data.find((item) => {
        //             return item.positionType == "index2";
        //           }).img
        //         : "",
        //       pic3: res.data.data.find((item) => {
        //         return item.positionType == "index3";
        //       })
        //         ? res.data.data.find((item) => {
        //             return item.positionType == "index3";
        //           }).img
        //         : "",
        //       pic4: res.data.data.find((item) => {
        //         return item.positionType == "index4";
        //       })
        //         ? res.data.data.find((item) => {
        //             return item.positionType == "index4";
        //           }).img
        //         : "",
        //       pic5: res.data.data.find((item) => {
        //         return item.positionType == "index5";
        //       })
        //         ? res.data.data.find((item) => {
        //             return item.positionType == "index5";
        //           }).img
        //         : "",
        //       pic6: res.data.data.find((item) => {
        //         return item.positionType == "index6";
        //       })
        //         ? res.data.data.find((item) => {
        //             return item.positionType == "index6";
        //           }).img
        //         : "",
        //     };
        //     resolve();
        //   });
        // }),
      ]).then((res) => {
        // this.$loading.done();
      });
    },
    handleLink(link) {
      location.href = link;
    },
    handleAddCart(val) {
      if (val.skus && val.skus.length == 1) {
        this.$bus.$emit("addCart", val.skus[0].sku_id);
      } else {
        this.skus = val.skus;
        this.listVisible = true;
      }
    },
    changeTableMaxHeight() {
      let width = document.body.offsetWidth; // 网页可视区域高度
      if (width < 650) {
        this.goodsGroupCount = 2;
        this.brandsGroupCount = 5;
      } else {
        this.goodsGroupCount = 4;
        this.brandsGroupCount = 9;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.home-page {
  .header {
    .header-pc {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      z-index: 50;
      height: auto;
      .primary-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 81px;
        background: #fff;
        border-bottom: 1px solid #e6e7e8;
        .primary-menu {
          display: flex;
          align-items: center;
          height: 100%;
        }
        .primary-serch {
          margin-right: 2rem;
        }
      }
    }
  }
}
.hot {
  margin-top: 3px;

  .line1 {
    margin-bottom: 3px;
    display: flex;
    justify-content: space-between;

    .left {
      width: 50vw;
      height: 38vw;
      background: #ccc;
      margin-right: 10px;
    }

    .right {
      margin-left: 10px;
      width: 50vw;
      height: 38vw;
      background: #ccc;
    }
  }

  .line2 {
    margin-top: 20px;
    height: 31.25vw;
    background: #ccc;
  }
}

.list-content {
  width: 100%;

  .list-sku {
    width: 100%;
    border-bottom: 1px solid #eee;
    line-height: 35px;
    height: 35px;
    cursor: pointer;
  }
}

/deep/.el-dialog__body {
  padding-top: 0px;
}

.selTitle {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .day {
    font-size: 16px;
    font-family: GillSans;
    font-weight: 600;
    line-height: 19px;
  }

  .line {
    width: 74px;
    height: 1px;
    background: #000000;
    margin: 10px 0;
  }

  .month {
    font-size: 16px;
    font-family: GillSans;
    font-weight: 600;
    line-height: 19px;
  }

  .text {
    text-align: center;
    font-size: 60px;
    font-weight: 600;
    line-height: 72px;
  }
}

.selected {
  display: flex;
  flex-wrap: wrap;

  .item {
    width: calc((100vw - 18px) / 4);
    cursor: pointer;
    margin-right: 6px;

    &:nth-child(4n) {
      margin-right: 0;
    }

    &:nth-child(n + 5) {
      margin-top: 40px;
    }

    .pic {
      height: 29.1vw;
      background: #d8d8d8;
    }

    .name {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      margin: 30px 0 8px;
      text-align: center;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .subName {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
    }

    .price {
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
      margin: 70px 0 30px;
      text-align: center;
    }

    .addCart {
      width: 160px;
      height: 40px;
      background: #e8c9a0;
      border-radius: 6px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      font-family: GillSans;
      font-weight: 600;
      color: #ffffff;
      margin: 0 auto;
    }
  }
}

.recien {
  .title {
    height: 232px;
    text-align: center;
    line-height: 232px;
    font-size: 60px;
    font-weight: 600;
    color: #000000;
  }

  .block {
    .line1 {
      height: 24.5vw;
      background: #d8d8d8;
      margin-bottom: 3px;
    }

    .line2 {
      display: flex;
      justify-content: space-between;

      .item {
        width: calc((100vw - 3px) / 2);
        overflow: hidden;

        .pic {
          width: 100%;
          height: 24.5vw;
          background: #d8d8d8;
        }

        .sex {
          font-size: 36px;
          font-weight: 600;
          color: #000000;
          line-height: 43px;
          text-align: center;
          margin: 40px 0 80px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .selTitle {
    height: 200px;

    .text {
      font-size: 22px;
      line-height: 45px;
    }
  }

  .selected {
    flex-wrap: wrap;

    .item {
      width: calc((100vw - 6px) / 2);
      padding-bottom: 20px;

      &:nth-child(2n) {
        margin-right: 0;
      }

      .pic {
        height: 55vw;
      }

      .name {
        margin: 3vw 0 2vw;
      }

      .price {
        margin: 2vw 0;
      }

      .addCart {
        width: 130px;
        line-height: 42px;
      }
    }
  }

  .recien {
    .title {
      font-size: 22px;
      height: 100px;
      line-height: 100px;
    }

    .block {
      .sex {
        font-size: 22px !important;
        line-height: 40px !important;
        margin: 20px 0 40px !important;
      }
    }
  }
}
</style>
