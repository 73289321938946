var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner",style:(("height: " + _vm.carouselHeight + "px"))},[_c('el-carousel',{attrs:{"loop":_vm.loop,"interval":_vm.interval,"indicator-position":_vm.indPosition}},_vm._l((_vm.recommendInChunks),function(chunks,i){return _c('el-carousel-item',{key:i},[(chunks && chunks.length)?_c('div',{staticClass:"item-group"},_vm._l((chunks),function(item,ii){return _c('div',{key:ii,staticClass:"item-box"},[_c('div',{staticClass:"img-wrap",on:{"click":function($event){return _vm.$router.push(
                ("/" + (_vm.$store.state.platform == 'pc'
                    ? 'GoodsDetail'
                    : 'Detail_Mobile') + "?id=" + (item.spuId))
              )}}},[_c('img',{staticClass:"banner-pic",attrs:{"src":item.cover}})]),_c('div',{staticClass:"desc"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.spuName))]),_c('div',{staticClass:"price"},[_vm._v(_vm._s(item.price))]),_c('div',{staticClass:"add-btn"},[_c('div',{staticClass:"add-cart",on:{"click":function($event){return _vm.handleAddCart(item)}}},[_vm._v(" "+_vm._s(_vm.$t("goodDetail.addCart"))+" ")])])])])}),0):_vm._e()])}),1),_c('div',{on:{"click":function($event){$event.stopPropagation();return (function () {})($event)}}},[_c('el-dialog',{attrs:{"title":"Talla","visible":_vm.listVisible,"width":"30%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.listVisible=$event}}},[_c('div',{staticClass:"list-content"},_vm._l((_vm.skus),function(item,index){return _c('p',{key:index,staticClass:"list-sku",on:{"click":function($event){$event.stopPropagation();return (function () {
              _vm.$bus.$emit('addCart', item.skuId || item.sku_id);
              _vm.listVisible = false;
            })($event)}}},[_vm._v(" "+_vm._s(item.specInfo ? item.specInfo.replace(/Talla:/g, "") : item.spec_info ? item.spec_info.replace(/Talla:/g, "") : "")+" ")])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }