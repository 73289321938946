import { render, staticRenderFns } from "./banner_pc.vue?vue&type=template&id=013e386a&scoped=true&"
import script from "./banner_pc.vue?vue&type=script&lang=js&"
export * from "./banner_pc.vue?vue&type=script&lang=js&"
import style0 from "./banner_pc.vue?vue&type=style&index=0&id=013e386a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013e386a",
  null
  
)

export default component.exports