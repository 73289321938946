<template>
	<div class="banner" :style="{ height }">
		<el-carousel :loop="loop" :interval="interval">
			<el-carousel-item v-for="(item, i) in data" :key="i">
				<img class="bannerPic" @click="$router.push(`/GoodsList?cateId=${item.linkId}`)" :src="item.img" width="100%" />
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
export default {
	name: 'BannerPc',
	props: {
		loop: {
			type: Boolean,
			default: true
		},
		interval: {
			type: Number,
			default: 5000
		},
		data: {
			type: Array,
			default: []
		}
	},
	data() {
		return {
			banner: {
				progress: 0,
				current: 0,
				size: 0,
				item: []
			},
			height: ''
		};
	},
	watch: {
		data(res) {
			this.banner.item = res;
			this.banner.size = res.length;
			this.banner.progress = (100 / res.length).toFixed(0);
		}
	},
	created() {},
	mounted() {
		this.height = parseInt(window.innerWidth / 4.12) + 'px';
		window.addEventListener('resize', this.resize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resize);
	},
	methods: {
		handleLink(link) {
			location.href = link;
		},
		resize() {
			this.height = parseInt(window.innerWidth / 4.12) + 'px';
		},
		onBannerChange(res) {
			//banner翻页
			this.banner.progress = ((res + 1) / this.banner.size) * 100;
			this.banner.current = res;
		},
		setActive(type) {
			if (type == 'prev') {
				this.$refs.banner.prev();
			} else {
				this.$refs.banner.next();
			}
		}
	}
};
</script>

<style lang="less" scoped>
.banner {
	// height: 466px;
	background: #ccc;
	position: relative;
	/deep/.el-carousel {
		height: 100%;
		.el-carousel__container {
			height: 100%;
		}
		.el-carousel__indicators {
			.el-carousel__indicator {
				padding: 15px 6px;
				.el-carousel__button {
					width: 10px;
					height: 10px;
					border-radius: 50%;
				}
			}
		}
	}
	.bannerPic {
		width: 100%;
		object-fit: cover;
	}
}
</style>
