<template>
  <div class="banner" :style="`height: ${carouselHeight}px`">
    <el-carousel
      :loop="loop"
      :interval="interval"
      :indicator-position="indPosition"
    >
      <el-carousel-item v-for="(chunks, i) in recommendInChunks" :key="i">
        <div class="item-group" v-if="chunks && chunks.length">
          <div v-for="(item, ii) in chunks" :key="ii" class="item-box">
            <div
              class="img-wrap"
              @click="
                $router.push(
                  `/${
                    $store.state.platform == 'pc'
                      ? 'GoodsDetail'
                      : 'Detail_Mobile'
                  }?id=${item.spuId}`
                )
              "
            >
              <img class="banner-pic" :src="item.cover" />
            </div>
            <div class="desc">
              <div class="title">{{ item.spuName }}</div>
              <div class="price">{{ item.price }}</div>
              <div class="add-btn">
                <div class="add-cart" @click="handleAddCart(item)">
                  {{ $t("goodDetail.addCart") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div @click.stop="() => {}">
      <el-dialog
        title="Talla"
        :visible.sync="listVisible"
        width="30%"
        :before-close="handleClose"
      >
        <div class="list-content">
          <p
            class="list-sku"
            v-for="(item, index) in skus"
            :key="index"
            @click.stop="
              () => {
                $bus.$emit('addCart', item.skuId || item.sku_id);
                listVisible = false;
              }
            "
          >
            {{
              item.specInfo
                ? item.specInfo.replace(/Talla:/g, "")
                : item.spec_info
                ? item.spec_info.replace(/Talla:/g, "")
                : ""
            }}
          </p>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerPc",
  props: {
    loop: {
      type: Boolean,
      default: true,
    },
    groupCount: {
      type: Number,
      default: 1,
    },
    interval: {
      type: Number,
      default: 5000,
    },
    data: {
      type: Array,
      default: () => [],
    },
    indPosition: {
      type: String,
      default: "inside",
    },
    carouselHeight: {
      type: Number,
      default: 480,
    },
  },
  data() {
    return {
      banner: {
        progress: 0,
        current: 0,
        size: 4,
        item: [1, 2, 3, 4],
      },
      listVisible: false,
      skus: [],
    };
  },
  watch: {
    /* data(res){
			this.banner.item = res;
			this.banner.size = res.length;
			this.banner.progress = (100/res.length).toFixed(0);
		} */
  },
  computed: {
    recommendInChunks() {
      const chunks = [];
      for (
        var i = 0, chunkSize = this.groupCount, l = this.data.length;
        i < l;
        i += chunkSize
      ) {
        chunks.push(this.data.slice(i, i + chunkSize));
      }
      console.log(chunks);
      return chunks;
    },
    // loopData() {
    //   let arr = [],
    //     loopgroup = this.data;
    //   if (loopgroup && loopgroup.length) {
    //     for (var i = 0, len = loopgroup.length; i < len; i += this.groupCount) {
    //       arr.push(loopgroup.slice(i, i + this.groupCount));
    //     }
    //   } else {
    //   }
    //   return arr;
    // },
  },
  watch: {
    goodsGroupCount() {},
  },
  created() {},
  methods: {
    handleClose(done) {
      done();
    },
    handleAddCart(val) {
      console.log(val);
      // this.$bus.$emit('addCart', val.skus[0].skuId);
      if (val.skus && val.skus.length == 1) {
        this.$bus.$emit("addCart", val.skus[0].skuId);
      } else {
        this.skus = val.skus;
        console.log(this.skus);

        this.listVisible = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.list-content {
  width: 100%;

  .list-sku {
    width: 100%;
    border-bottom: 1px solid #eee;
    line-height: 35px;
    height: 35px;
    cursor: pointer;
  }
}
.banner {
  //background: #ccc;
  position: relative;
  padding: 40px 0;
  font-family: GillSans;
  /deep/.el-carousel {
    height: 100%;
    .el-carousel__container {
      height: 100%;
    }
    .el-carousel__indicators {
      .el-carousel__indicator {
        padding: 15px 6px;
        .el-carousel__button {
          background: #ccc;
          height: 3px;
          border-radius: 1px;
        }
      }
    }
  }
  /deep/.el-carousel__arrow {
    top: 33%;
  }
  .item-group {
    width: 100%;
    display: flex;
    justify-content: center;
    .item-box {
      width: 300px;
      margin: 0 10px;
      .img-wrap {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        position: relative;

        .banner-pic {
          position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      max-width: 80%;
      max-height: 80%;
      display: inline-block;
      vertical-align: middle;
      margin: auto;
        }
      }

      .desc {
        color: #757575;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 1px;
        margin-top: 5px;
        .title {
          margin: 5px 0;
		  min-height: 60px;
        }
        .price {
          font-weight: 700;
        }
        .add-btn {
          margin-top: 20px;
          font-weight: 700;
          .add-cart {
            width: 110px;
            text-align: center;
            line-height: 40px;
            border: 1px solid #ccc;
            border-radius: 3px;
            cursor: pointer;
            &:hover {
              background-color: #454545;
              color: #fff;
              transition: 0.5s ease-in-out;
            }
          }
        }
      }
    }
  }
}
</style>
